import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter as ChakraModalFooter,
  Flex
} from "@chakra-ui/react";

const Modal = ({
  isOpen,
  onClose,
  children,
  width = "25vw",
  closeButtonProps,
  ...props
}) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered={true} {...props}>
      <ModalOverlay />
      <ModalContent
        maxW={width}
        data-testid={props["data-testid"]}
        overflow="hidden"
        my="1em"
      >
        <ModalCloseButton
          variant="transparent"
          bgColor="transparent"
          border="none"
          cursor="pointer"
          rounded="full"
          color="mandala.black.900"
          _hover={{ bg: "mandala.black.100", color: "mandala.black.500" }}
          {...closeButtonProps}
        />
        {children}
      </ModalContent>
    </ChakraModal>
  );
};

const ModalFooter = ({ children, isCentered = true, ...props }) => {
  return (
    <ChakraModalFooter {...props}>
      {isCentered ? (
        <Flex w="100%" justify="center">
          {children}
        </Flex>
      ) : (
        children
      )}
    </ChakraModalFooter>
  );
};

export { Modal, ModalHeader, ModalBody, ModalFooter };
